@tailwind components;
@tailwind base;
@tailwind utilities;

/* Define a class for the grid container */
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* Spacing between columns */
}

/* Define a class for grid items (list items) */
.image-item {
  width: calc(50% - 10px);
  /* 50% width for 2 columns with some spacing */
  margin-bottom: 20px;
  /* Adjust spacing between rows */
}

/* Optional: If you want to center the images within each grid item */
.image-item img {
  display: block;
  margin: 0 auto;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Source Serif Pro', serif;
  color: #ffffff;
  background-color: #121212;
}

#mainHeader {
  background-color: #121212;
}

#db-app {
  max-width: 560px;
  margin: auto;
  min-height: 100vh;

  >div {
    @apply min-h-[100vh];

    &.page {
      @apply p-4;
    }
  }
}

.football-bg {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: -80px 80px;

  &.splash-page {
    background-position: 50%;
    background-size: 100% 100%;
  }
}

.page {
  @apply flex;
  @apply flex-col;
  @apply justify-start;
}

a {
  @apply text-link;
  @apply underline;
}

@layer base {
  h2 {
    @apply text-xl;
    @apply font-bold;
  }
}

.team-image {
  background-size: 26px 26px;
  background-position: 50% 50%;
}

.slot {
  .team-image {
    background-size: 36px 36px;
    background-position: 50% 50%;
  }
}

.green-text {
  color: rgb(0 190 42);
}

@keyframes blink {
  from {
    @apply opacity-0;
  }

  to {
    @apply opacity-100;
  }
}

@keyframes border-blink-league {
  from {
    @apply border-accent;
  }

  to {
    @apply border-gray;
  }
}

@keyframes bg-blink-slot {
  from {
    @apply bg-accent;
  }

  to {
    @apply bg-gray;
  }
}

@keyframes bg-blink-slot-reverse {
  from {
    @apply bg-gray;
  }

  to {
    @apply bg-accent;
  }
}

@keyframes bg-blink-slot-reverse-danger {
  from {
    @apply bg-gray;
  }

  to {
    @apply bg-danger;
  }
}

.animate-bg-blink {
  transition: all 1s ease-in-out;
  animation: bg-blink-slot 5s infinite;
}

.animate-timer-blink {
  transition: all 1s ease-out;
  animation: bg-blink-slot-reverse 1s infinite;
}

.animate-elapsed-timer-blink {
  transition: all 1s ease-out;
  animation: bg-blink-slot-reverse-danger 1s infinite;
}

.bg-white {
  background-color: #fff;
}

// REACT TOGGLE
.react-toggle {
  touch-action: pan-x;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0 10px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4d4d4d;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19ab27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128d15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4d4d4d;
  border-radius: 50%;
  background-color: #fafafa;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19ab27;
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099e0;
  -moz-box-shadow: 0px 0px 3px 2px #0099e0;
  box-shadow: 0px 0px 2px 3px #0099e0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099e0;
  -moz-box-shadow: 0px 0px 5px 5px #0099e0;
  box-shadow: 0px 0px 5px 5px #0099e0;
}